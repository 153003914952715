<template>
  <div class="Title">
    <div class="TitleLeft">
      <div>{{ label }}</div>
      <slot></slot>
    </div>
    <div class="TitleRight" v-if="icon">
      <a class="primary-circle" @click="$emit('icon')"><iconic :name="icon" /></a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["label", "icon"],
};
</script>

<style lang="scss" scoped>
.Title {
  position: relative;
  font-size: 150%;
  font-family: $third_font_bold;
  display: flex;
  justify-content: space-between;

  &[tcentered] {
    justify-content: center;
  }

  .TitleRight {
    font-size: 80%;
  }

  .SubTitle {
    font-size: 80%;
  }
}
</style>
